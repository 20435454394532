
import { Options, Vue } from "vue-class-component";
import PersonDetails from "@/components/PersonDetails.vue";
import DetailsHeader from "@/components/DetailsHeader.vue";
import DetailsRow from "@/components/DetailsRow.vue";
import Table from "@/components/Table.vue";
import Tooltip from "@/components/Tooltip.vue";
import Badge from "@/components/Badge.vue";
import DeleteIcon from "@/components/NoteTakerDetails/DeleteIcon.vue";
import CheckboxInput from "@/components/Form/CheckboxInput.vue";
import RadioButtonGroup from "@/components/Form/RadioButtonGroup.vue";
import TextArea from "@/components/Form/TextArea.vue";
import StudentService from "@/services/student-service";
import LocalStorageService from "@/services/localstorage-service";
import CourseService from "@/services/course-service";
import {
  Course,
  PersonResponse,
  SortOrder,
  Student,
  SupportCourseOccurrence,
} from "@/interfaces/api";
import { Constants } from "@/constants/constants";
import DateHelper from "@/helpers/date-helper";
import ArrayHelper from "@/helpers/array-helper";
import { IUserPagingData } from "@/interfaces/IUserPagingData";

@Options({
  components: {
    DetailsHeader,
    DetailsRow,
    Table,
    DeleteIcon,
    CheckboxInput,
    RadioButtonGroup,
    TextArea,
    PersonDetails,
    Badge,
    Tooltip,
  },
  data: () => ({
    /*
     * Holds the checked support course instances
     */
    newCheckedSupportCourseOccurrences: [],
    /*
     * Holds the unchecked support course instances
     */
    newUncheckedSupportCourseOccurrences: [],
    /*
     * Holds the emited boolean value from PersonDetails
     */
    editEnable: false,
    /*
     * Holds the id of the student and is populated from the route's id parameter when the page loads
     */
    currentStudentId: 0,
    /*
     * Holds the student data and is populated by a call to the student service when the page loads
     */
    student: null,
    /**
     * Required if showCoordinator
     * The functionId to fetch coordinators from
     */
    functionId: Constants.NoteTakingFunctionId,
    /*
     * The background color for the tooltip when mousing over course occurrence
     */
    tooltipBgColor: "#875E29",
    /**
     * The current sort column
     */
    sortColumn: "courseOccurrenceStart",
    /**
     * If sort order is ascending
     */
    ascending: true,
    /**
     * Holds the help state of supportCourseOccurrences
     */
    supportCourseOccurrencesFlat: {},
    /**
     * Holds the copy of help state of supportCourseOccurrences
     */
    supportCourseOccurrencesFlatCopy: null,
    /**
     * Holds the course occurrence id
     */
    courseOccurrenceId: 0,
    /**
     * Populated with student courses which have a start date later than the granted support date
     */
    coursesWithLaterStartDate: new Array<SupportCourseOccurrence>(),
    /**
     * If the student is an active note-taker
     */
    isActiveNoteTaker: false,
    /**
     * The id of the active note-taker
     */
    noteTakerId: 0,
  }),
  computed: {
    /**
     * Get student support course occurrences
     */
    supportCourseRows() {
      const student: Student = this.student;
      const supportCourseOccurrences =
        student.supportCourseOccurrences as SupportCourseOccurrence[];
      ArrayHelper.sortByProperty<SupportCourseOccurrence>(
        supportCourseOccurrences,
        this.sortColumn,
        this.ascending
      );
      return supportCourseOccurrences;
    },
    /**
     * Hides all unwanted columns
     */
    hiddenSupportCourseColumns() {
      const hidden = [
        "titleEn",
        "titleSv",
        "courseUid",
        "offerDate",
        "assignmentStart",
        "assignmentEnd",
        "offerConfirmedDate",
        "deletedByAdmin",
      ];
      return hidden;
    },
    /*
     * Used by the previous/next button logic
     */
    allStudentIds() {
      const filteredStudentIds = LocalStorageService.getItem<number[]>(
        Constants.FilteredStudentIds
      );
      return filteredStudentIds;
    },

    /*
     * The id of the student to load when the next button is clicked
     */
    nextStudentId() {
      const currentIdIndex = this.allStudentIds.indexOf(this.currentStudentId);
      let nextIdIndex = currentIdIndex + 1;
      if (nextIdIndex >= this.allStudentIds.length) {
        nextIdIndex = 0;
      }
      return this.allStudentIds.slice(nextIdIndex, nextIdIndex + 1)[0];
    },
    /*
     * The id of the student to load when the previous button is clicked
     */
    previousStudentId() {
      const currentIdIndex = this.allStudentIds.indexOf(this.currentStudentId);
      let previousIdIndex = currentIdIndex - 1;
      if (previousIdIndex < 0) {
        previousIdIndex = this.allStudentIds.length - 1;
      }
      return this.allStudentIds.slice(previousIdIndex, previousIdIndex + 1)[0];
    },
    /**
     * The date that the student is granted support until
     */
    grantedSupportDate(): string {
      return this.student ? this.student.grantedSupportDate : null;
    },
    /**
     * Indicates if the granted support date is earlier than any of the selected course start dates
     */
    hasLaterStartDates(): boolean {
      return this.coursesWithLaterStartDate.length > 0;
    },
    /**
     * The text displayed to the user if the granted support date is earlier than any of the selected course start dates
     */
    laterStartDateText(): string {
      return this.$t("add-student.later-start-date");
    },
    /*
     * Selected courses
     */
    selectedCourses(): Array<SupportCourseOccurrence> {
      const selected = new Array<SupportCourseOccurrence>();
      Object.entries(this.supportCourseOccurrencesFlat).forEach(
        ([key, value]) => {
          if (value) {
            const course = this.supportCourseRows.find(
              (x: SupportCourseOccurrence) => x.courseUid === key
            );
            if (course) {
              selected.push(course);
            }
          }
        }
      );
      if (this.student && this.student.supportCourses) {
        this.student.supportCourses = selected.map(
          (course: SupportCourseOccurrence) => {
            return {
              courseUid: course.courseUid,
              courseOccurrenceId: course.courseOccurrenceId,
            };
          }
        );
      }
      return selected;
    },
  },
  methods: {
    /*
     * Enables edit to activate checkboxes
     */
    edit() {
      this.editEnable = true;
      this.supportCourseOccurrencesFlatCopy = {
        ...this.supportCourseOccurrencesFlat,
      };
    },
    /*
     * Cancels edit to disable checkboxes
     */
    cancel() {
      this.checkGrantedSupportDate();
      this.editEnable = false;
      Object.assign(
        this.supportCourseOccurrencesFlat,
        this.supportCourseOccurrencesFlatCopy
      );
      this.supportCourseOccurrencesFlatCopy = null;
    },
    /*
     * Save edit
     */
    save() {
      this.getStudent();
      this.editEnable = false;
    },
    /*
     * Get course Id for the given course Uid
     */
    getCourseIdByUid(courseUid: string): number | undefined {
      const student = this.student as Student;
      const courseInstance = student.supportCourseOccurrences?.find(
        (courseInstance) => {
          return courseInstance.courseUid == courseUid;
        }
      );
      return courseInstance?.courseOccurrenceId;
    },
    /**
     * Returns a color based on status
     *
     * @param {string} status The status we want a color for
     */
    statusBgColor(status: string): string {
      return CourseService.courseOccurrenceStatusColor(status);
    },
    /**
     * Returns a color based on status
     *
     * @param {string} status The status we want a color for
     */
    statusTextColor(status: string): string {
      return status === "Yellow" ? "black" : "white";
    },
    /**
     * Returns a text based on status
     *
     * @param {string} status The status we want a tooltip text for
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    statusBgText(status: string): string {
      return this.$t(CourseService.courseOccurrenceStatusText(status));
    },
    /**
     * Triggered when the user changes the sort column/order of the course table
     */
    onSortChange(columnName: string, ascending: boolean): void {
      this.sortColumn = columnName;
      this.ascending = ascending;
      LocalStorageService.setItem(
        Constants.StudentTableUserPagingData,
        this.generateUserPagingData()
      );
      this.sortCourseOccurrences();
    },
    /**
     * Triggered when the user changes the sort column/order of the course table
     */
    sortCourseOccurrences(): void {
      ArrayHelper.sortByProperty<SupportCourseOccurrence>(
        this.supportCourseRows,
        this.sortColumn,
        this.ascending
      );
    },
    /**
     * Generates user paging data to be saved to local storage
     */
    generateUserPagingData(): Partial<IUserPagingData> {
      const userPagingData: Partial<IUserPagingData> = {
        sortColumn: this.sortColumn,
        sortOrder: this.ascending ? SortOrder.Asc : SortOrder.Desc,
      };

      return userPagingData;
    },
    /**
     * Gets the user's paging data that has been saved to local storage
     */
    getUsersPagingData(): void {
      const userPagingData = LocalStorageService.getItem<
        Partial<IUserPagingData>
      >(Constants.StudentTableUserPagingData);

      if (userPagingData) {
        this.sortColumn = userPagingData.sortColumn;
        this.ascending = userPagingData.sortOrder === SortOrder.Asc;
      }
    },
    /**
     * Fetches the student with the id from route parameter
     */
    getStudent(): void {
      if (this.$route.name === "StudentDetails" && this.$route.params.id) {
        this.currentStudentId = parseInt(this.$route.params.id);
        StudentService.getStudentById(this.currentStudentId)
          .then((data: PersonResponse) => {
            this.student = data.student;
            this.isActiveNoteTaker = data.isActiveNoteTaker;
            this.noteTakerId = data.noteTakerId;
            data.student?.supportCourseOccurrences?.forEach(
              (supportCourseOccurrence) => {
                const active =
                  supportCourseOccurrence.assignmentStart !== null &&
                  supportCourseOccurrence.assignmentEnd === null;
                if (
                  !this.supportCourseOccurrencesFlat[
                    supportCourseOccurrence.courseUid
                  ]
                ) {
                  this.supportCourseOccurrencesFlat[
                    supportCourseOccurrence.courseUid
                  ] = active;
                }
              }
            );
          })
          .catch((e: Error) => {
            console.error(e);
          });
      }
    },
    /*
     * Changes and saves coordiantor to student
     */
    updateStudent(): void {
      StudentService.addOrUpdateStudent(this.student);
    },
    /*
     * Navigates to a student with the specified id
     * Called from the next/previous button click
     */
    navigateToStudent(id: number): void {
      this.$router.push({
        name: "StudentDetails",
        params: { id: id },
      });
    },
    navigateToOverview(): void {
      this.$router.push({
        name: "StudentList",
      });
    },
    /**
     * Checks if the specified value is unavailable and if so,
     * replaces it with the the unavailable translation for the current locale
     * Also checks if the value is a date and if so, formats it to YYYY-MM-DD
     */
    replaceUnvailableOrFormatDate(value: string): string {
      return CourseService.replaceUnvailableOrFormatDate(
        value,
        this.$t("course-occurrence.unavailable")
      );
    },
    /**
     * Sets the course title to be used as text for tooltip over link to course detail
     */
    getCourseTitle(titleSv: string, titleEn: string): string {
      if (titleSv === Constants.NotAvailable) {
        return CourseService.getCourseTitleTooltipText(
          this.$t("course-occurrence.title"),
          this.$t("course-occurrence.unavailable")
        );
      }

      return this.$i18n.locale === "sv" ? titleSv : titleEn;
    },
    /**
     * Checks if the granted support date is earlier than any of the selected course start dates
     */
    checkGrantedSupportDate(): void {
      if (this.editEnable && this.grantedSupportDate) {
        this.coursesWithLaterStartDate = this.selectedCourses.filter(
          (course: SupportCourseOccurrence) =>
            DateHelper.dateAisBeforeDateB(
              this.grantedSupportDate,
              course.courseOccurrenceStart as string
            )
        );
      }
    },
    /**
     * Checks if the speified course has a later date than the granted support date
     * Used to highlight the course in the GUI
     */
    isCourseWithLaterStartDate(courseUid: string): boolean {
      return (
        this.coursesWithLaterStartDate.find(
          (x: SupportCourseOccurrence) => x.courseUid === courseUid
        ) !== undefined
      );
    },
    /**
     * Regulates the coursesWithLaterStartDate array
     */
    onCourseCheckedChanged(): void {
      this.checkGrantedSupportDate();
    },
    /*
     * The message displayed in the GUI if the person's name is to be used
     */
    showNameMsg(localeKey: string): string {
      const msg = this.$t(localeKey, {
        full_name: `${this.student.firstName} ${this.student.lastName}`,
      });
      return msg;
    },
    /**
     * Resets the selected courses
     */
    resetSelectedCourses(): void {
      this.supportCourseOccurrencesFlat = {};
      this.selectedCourses = new Array<SupportCourseOccurrence>();
    },
    /**
     * Checks whether the course with the specified course Uid has an end date that has passed
     * Used for setting the disabled attribute on the course checkbox
     *
     * @param {string} courseUid The course Uid
     * @returns {boolean} whether the course has an end date that has passed
     */
    isCompletedCourse(courseUid: string): boolean {
      return CourseService.isCompletedCourse(courseUid, this.student.courses);
    },
    /**
     * Gets a title attribute value for the course checkbox
     *
     * @param {string} courseUid The course Uid
     * @returns {boolean} A string containing the course's start and end dates
     */
    getTitle(courseUid: string): string {
      if (!this.student.courses) {
        return "";
      }

      const foundCourse = this.student.courses.find(
        (course: Course) =>
          course.courseUid.toLowerCase() === courseUid.toLowerCase()
      );

      if (!foundCourse) {
        return "";
      }

      return `${this.$t("course-occurrence.courseStartDate")}: ${
        foundCourse.courseStartDate
      } | ${this.$t("course-occurrence.courseEndDate")}: ${
        foundCourse.courseEndDate
      }`;
    },
  },
  mounted() {
    this.getUsersPagingData();
    this.getStudent();
    const localstorageLang = LocalStorageService.getItem<string>("language");
    this.$root.$i18n.locale = localstorageLang;
  },
  unmounted() {
    this.resetSelectedCourses();
  },
  watch: {
    $route() {
      this.resetSelectedCourses();
      this.getStudent();
    },
    selectedCourses() {
      this.onCourseCheckedChanged();
    },
    grantedSupportDate() {
      this.checkGrantedSupportDate();
    },
  },
})
export default class StudentDetails extends Vue {}
